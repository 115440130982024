









































  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { Validator } from '@/constant/Mixins';
  import { UserModel } from '@/store/auth/types';
  import { ContentModel } from '@/store/types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'Login',
    components: {},
  })
  export default class Login extends Mixins(Validator) {
    @State('user', { namespace: 'auth' }) form!: UserModel;
    @Action('login', { namespace: 'auth' }) login!: (params?: any) => Promise<string>;
    @State('page_content') page_content!: ContentModel[];

    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.LOGIN_REGISTER)[0]?.image ?? require('@/assets/images/logo.white.png');
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      (this.$refs['email'] as any).focus();
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      const formEl = this.$refs['form-login'] as Element;
      if (formEl?.querySelector('.is-invalid') === null) {
        this.login(this.form).then((token) => {
          let redirect = localStorage.getItem('redirect');
          if (redirect) {
            localStorage.removeItem('redirect');
            this.$router.push(JSON.parse(redirect));
          } else this.$router.push({ name: 'dashboard' });
        });
      }
    }
  }
